<template>
    <div class="base-width">
        <div class="add-form">
            <el-row :gutter="15">
                <el-col :offset="4" :span="16">
                    <div :class="{admin:isAdmin}" class="form-area">
                        <div class="error">
                            <span v-show="error.show">
                                {{ error.error }}
                            </span>
                        </div>
                        <div class="item">
                            <el-input :autosize="{ minRows: 1, maxRows: 4}" minlength="5" maxlength="100" show-word-limit type="textarea" @blur="eventBlur" placeholder="问题标题(5~100个字)"
                                      v-model="answer.title"></el-input>
                        </div>
                        <div  v-if="isAdmin" class="item radio-item">
                            <el-radio-group v-model="answer.type_id">
                                <el-radio v-for="item in $store.state.dict.answer_type" :key="item.value" :label="item.value">{{item.label}}</el-radio>
                            </el-radio-group>
                        </div>
                        <div v-if="isAdmin" class="item">
                            <file  ref="file" :thumb="false" :file="answer.attach_list" accept=".zip" :title="false"></file>
                        </div>
                        <div class="item">
                            <div style="width: 100%">
                                <Toolbar
                                    ref="toolbar"
                                    class="toolbar"
                                    :editor="editor"
                                    :defaultConfig="toolbarConfig"
                                />
                                <!-- 编辑器 -->
                                <Editor
                                    class="editor"
                                    :defaultConfig="editorConfig"
                                    v-model="answer.content"
                                    @onBlur="editorBlur"
                                    @onCreated="editorCreated"
                                />
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <div class="btn-area">
                <el-row :gutter="15">
                    <el-col :offset="4" :span="16">
                        <div class="btn-div">
                            <el-button @click="addAnswer" type="primary">发布问题</el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>

import '@wangeditor/editor/dist/css/style.css';
import {Editor, Toolbar} from "@wangeditor/editor-for-vue";
import file from "@/components/common/file.vue";
// import XX from './abcd';

export default {
    mounted() {
        // this.loopx();
        // window.onbeforeunload = function(e) {
        //     return '数据还没有保存，确定离开此页吗？';
        // }
        if (!this.$tools.checkLogin()) {
            return;
        }
        if (this.$route.query.title) {
            //如果是从没有找到问题的界面跳转过来，那么要还原这个标题
            this.answer.title = this.$route.query.title;
        }
        if (this.$route.query.answer_id) {
            //如果是修改问题，那么要将这个问题的数据还原
            this.loadAnswerData();
        }
    },
    data() {
        return {
            answer: {
                answer_id: '',
                title: '',
                type_id: 0,
                content: '',
                attach_list:[],
            },
            error: {
                show: false,
                error: '',
            },
            editor: null,
            baseUrl: '/bbs/answer/index',
            baseViewUrl:'/bbs/answer/view',
            toolbarConfig: {
                toolbarKeys: ["headerSelect", 'uploadImage', "color", "bgColor", "|", "fontSize", "lineHeight", "|", {
                    "key": "group-justify",
                    "title": "对齐",
                    "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z\"></path></svg>",
                    "menuKeys": ["justifyLeft", "justifyRight", "justifyCenter", "justifyJustify"]
                }, {
                    "key": "group-indent",
                    "title": "缩进",
                    "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z\"></path></svg>",
                    "menuKeys": ["indent", "delIndent"]
                }, "|", "emotion", "insertLink",
                    "insertTable", "codeBlock", "divider", "|", "undo", "redo", "|", "fullScreen"]
            },
            editorConfig: {
                autoFocus: false,
                placeholder: '请输入问题内容',
                MENU_CONF: {
                    uploadImage: {
                        customUpload: async (file, insertFn) => {
                            let formData = new FormData();
                            formData.append('image', file);
                            let data = await this.$axiosUpload.post('', formData).catch(() => {

                            });
                            if (!data) {
                                this.$message.warning('图片上传失败!');
                                return;
                            }
                            insertFn(data.data.src);
                        }
                    }
                }
            },
        }
    },
    components: {
        Editor, Toolbar,file
    },
    computed:{
        isAdmin:function (){
            return !!this.$store.state.userData&&this.$store.state.userData.group_id===1;
        },
    },
    methods: {
        // loopx(){
        //     let current=XX.shift();
        //     if (current){
        //         this.$bbs.post(this.baseUrl,current).then(()=>{
        //             this.loopx();
        //         })
        //     }
        // },
        loadAnswerData() {
            this.$bbs.get(this.baseViewUrl, {
                params: {
                    answer_id: this.$route.query.answer_id,
                }
            }).then(data => {
                let editData={};
                //只需要下面的三个字段;
                ['answer_id','title','content','type_id','attach_list'].map(v=>{
                    editData[v]=data.data[v];
                });
                this.answer = editData;
            });
        },
        editorBlur: function () {
            if (!this.answer.content || this.answer.content.length <= 10) {
                this.error.show = true;
                this.error.error = '内容太少了，请继续添加';
                return;
            }
            this.error.error = '';
            this.error.show = false;
            return true;
        },
        eventBlur: function () {
            if (!this.answer.title) {
                this.error.show = true;
                this.error.error = '标题不可以为空';
                return;
            }
            if (this.answer.title.length < 5) {
                this.error.show = true;
                this.error.error = '标题长度太少啦';
                return;
            }

            if (this.answer.title.length > 100) {
                this.error.show = true;
                this.error.error = '标题长度不可以超过100字';
                return;
            }
            this.error.error = '';
            this.error.show = false;
            return true;
        },
        editorCreated(editor) {
            this.editor = Object.seal(editor);
        },
        addAnswer() {
            if (!this.$tools.checkLogin()) {
                return;
            }
            if (!this.eventBlur() || !this.editorBlur()) return;
            if (this.isAdmin&&!this.answer.type_id){
                this.error.show = true;
                this.error.error = '请指定分类';
                return;
            }
            if (this.answer.answer_id) {
                //如果是修改问题
                this.$bbs.put(this.baseUrl, this.answer, {need: {error: true}}).then((answer) => {
                    this.$message.success('修改成功!');
                    this.$router.push({
                        path: '/wenda/detail/' + answer.data.answer_id + '.html',
                    });
                    //这里触发一个事件;
                    // this.$store.commit('editAnswer', {data: answer.data, edit: Math.random()});
                    // this.$router.push({
                    //     path: '/wenda/detail/'+answer.answer_id+'.html',
                    // });
                });
            } else {
                this.$bbs.post(this.baseUrl, this.answer, {need: {error: true}}).then((answer) => {
                    this.$message.success('发布成功!');
                    this.$router.push({
                        path: '/wenda/detail/' + answer.data.answer_id + '.html',
                    });
                });
            }
        },
    }
}

</script>
<style scoped lang="less">
.add-form {
    margin: 0 10px;
}

.form-area {
    min-height: calc(100vh - 200px);
    padding: 20px 0;
    &.admin{
        .item{
            .editor {
                height: calc(100vh - 430px);
            }
        }
    }
    .error {
        color: red;
        height: 35px;
        padding: 10px 5px;
    }

    .item {
        background: #fff;
        margin-bottom: 25px;

        .editor {
            height: calc(100vh - 330px);
        }
    }

    .radio-item{
        padding: 15px 10px;
    }
}

.btn-area {
    align-items: center;
    position: fixed;
    bottom: 10px;
    left: 0;
    text-align: right;
    right: 0;
    z-index: 9;
    background: #fff;

    .btn-div {
        margin: 0 7.5px;
        padding: 5px 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
</style>
